import axios, { AxiosResponse } from "axios";

async function logout(context: any) {
  await context.$http
    .post(`${context.$backEndUrl}/v1/auth/logout`, {
      refreshToken: context.$store.getters.REFRESH_TOKEN,
    })
    .then((res: AxiosResponse) => {
      console.log("Response from proxy api obtained (logout)", res);
    })
    .finally(() => {
      context.$store.dispatch("RESET_AUTH").then(() => {
        context.$router.push("/login");
      });
    });
}

export { logout };
