import { createApp } from "vue";
import { panelBackUrl } from "./config";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { createBasicHTTP, createBasicTaskHTTP } from "./basic-http";

const app = createApp(App);

app.config.globalProperties.$backEndUrl = panelBackUrl;

app.config.globalProperties.$http = createBasicHTTP();

app.config.globalProperties.$http_task = createBasicTaskHTTP(panelBackUrl);

app.use(store).use(router).mount("#app");
