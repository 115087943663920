import axios, {AxiosRequestConfig, AxiosResponse, AxiosError} from "axios";
import store from "./store";
import router from "./router";

function createBasicHTTP() {
    const http = axios.create({timeout: 10000});

    // set request interceptors
    http.interceptors.request.use(
        async (config: any) => {
          config.headers["Authorization"] = `Bearer ${await store.getters
            .AUTH_TOKEN}`;
          return config;
        },
        function (error: AxiosError) {
          return Promise.reject(error);
        }
      );
    
      // set response interceptors
      http.interceptors.response.use(
        (response: any) => response,
        async (error: any) => {
          if (error.response && error.response.status == 401) {
            console.log("Auth token expired try to refresh");
            const isUpdated = await store.dispatch("UPDATE_AUTH_TOKEN");
            if (isUpdated) {
              error.config.headers["Authorization"] = `Bearer ${await store.getters
                .AUTH_TOKEN}`;
              return http.request(error.config);
            } else {
              router.push("/login");
            }
          }
      
          return Promise.reject(error);
        }
      );

      return http
}
 

function createBasicTaskHTTP(backEndUrl: string) {
    const basicHttp = createBasicHTTP()
    const http = axios.create();

    http.interceptors.request.use(
        async (config: any) => {
          config.headers["Authorization"] = `Bearer ${await store.getters
            .AUTH_TOKEN}`;
          return config;
        },
        function (error: AxiosError) {
          return Promise.reject(error);
        }
      );
      
      http.interceptors.response.use(
        async (response: any) => {
          let result = response;
      
          if (
            response &&
            response.status === 200 &&
            ["WAITING", "INPROCESS"].includes(response.data.payload.taskState)
          ) {
            let tries = 20;
      
            while (
              ["WAITING", "INPROCESS"].includes(response.data.payload.taskState) &&
              tries > 0
            ) {
              result = await basicHttp
                .post(`${backEndUrl}/v1/proxy/api`, {
                  url: `/v2/task?taskId=${response.data.payload.taskId}`,
                  method: "GET",
                  payload: {},
                  headers: {},
                })
                .then((res: AxiosResponse) => {
                  if (["OK", "ERROR"].includes(res.data.payload.taskState)) {
                    tries = 0;
                    return res;
                  }
                })
                .finally(() => {
                  tries -= 1;
                });
      
              await new Promise((resolve) => {
                setTimeout(() => {
                  console.log(
                    "Retry to get result from task id:",
                    response.data.payload.taskId
                  );
                  resolve(null);
                }, 1000);
              });
            }
          }
          return result;
        },
        async (error: any) => {
          if (error.response && error.response.status == 401) {
            console.log("Auth token expired try to refresh");
            const isUpdated = await store.dispatch("UPDATE_AUTH_TOKEN");
            if (isUpdated) {
              error.config.headers["Authorization"] = `Bearer ${await store.getters
                .AUTH_TOKEN}`;
              return basicHttp.request(error.config);
            } else {
              router.push("/login");
            }
          }
      
          return Promise.reject(error);
        }
      );
      return http

}

export {createBasicHTTP, createBasicTaskHTTP}