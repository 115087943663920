const panelBackUrl = process.env.https://panel-v3-demo.tplatform.net;
const sshUser = process.env.root;
const sshHost = process.env.task-gateway.tplatform.net;
const namespace = process.env.demo;
const keyclockUrl = process.env.https://keycloak-demo.tplatform.net;
const keyclockRealm = process.env.api-service;
const keyclockClientId = process.env.admin_panel;
const playStatusInterval = parseInt(
  process.env.5,
);
const integrationMode = process.env.GROUND;

export {
  panelBackUrl,
  sshUser,
  sshHost,
  namespace,
  keyclockUrl,
  keyclockRealm,
  keyclockClientId,
  playStatusInterval,
  integrationMode,
};
