<template>
  <div id="content">
    <div class="content_root_container">
      <div class="content_root_container__item">
        <TopBar @switch-hide-event="changeLeftBarState" />
      </div>
      <div
        v-if="leftBar.allowContent.length > 0"
        class="content_root_container__item main_container"
      >
        <div
          class="main_container__item left_bar"
          :class="leftBar.smallMode ? 'left_bar_small' : ''"
        >
          <LeftBar
            :smallMode="leftBar.smallMode"
            :defaultTab="this.$route.name.toLowerCase()"
            :allowContent="leftBar.allowContent"
          />
        </div>
        <div
          class="main_container__item content"
          :class="leftBar.smallMode ? 'content_large' : ''"
        >
          <div class="content_wrapper">
            <Transition name="slide" mode="out-in">
              <component :is="getComponent"></component>
            </Transition>
          </div>
        </div>
      </div>
      <div v-else class="no_rights_container">
        Обратитесь к администратору для получения прав
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import LeftBar from "@/components/LeftBar.vue";
import { defineAsyncComponent } from "vue";
import { logout } from "@/auth";

export default {
  components: {
    TopBar,
    LeftBar,
  },
  data() {
    return {
      leftBar: {
        smallMode: true,
        allowContent: [],
      },
    };
  },
  methods: {
    logout,
    // hide left nav bar
    changeLeftBarState() {
      this.leftBar.smallMode = !this.leftBar.smallMode;
    },
  },
  computed: {
    getComponent() {
      if (this.$route.name) {
        return defineAsyncComponent(() => import(`./${this.$route.name}.vue`));
      }
      return defineAsyncComponent(() => import(`./Terminals.vue`));
    },
  },

  mounted() {
    // update rights if not not recieved if previos versions
    if (this.$store.getters.USER_RIGHTS === null) {
      logout(this);
    } else {
      this.leftBar.allowContent = this.$store.getters.USER_RIGHTS;
    }
  },
};
</script>

<style lang="less">
@import "../assets/styles/colors.less";

.no_rights_container {
  display: flex;
  justify-content: center;
  margin-top: 20%;
  font-size: 35px;
  color: @font-contranst-color;
}

.slide-enter-active {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}
.slide-leave-active {
  transition: opacity 0.3s ease-out;
}

.slide-enter-from {
  opacity: 0;
}
.slide-enter-to {
  opacity: 1;
  // transform: scale(1.1);
}
.slide-leave-from {
  opacity: 1;
}
.slide-leave-to {
  opacity: 0;
}
.content_root_container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.main_container {
  display: flex;
  // position: fixed;
  height: 100%;
  width: 100%;
}

.content {
  background-color: #ffffff;
  width: calc(100% - 206px);
  height: 100%;
  transition: 0.3s;
  .content_wrapper {
    padding: 20px 20px 0px 0px;
  }

  @media (max-width: 460px) {
    &.content_large {
      width: 100%;
      transition: 0.1s;
      transition-property: left;
    }
    width: calc(100% - 57px);
  }
  @media (min-width: 461px) {
    &.content_large {
      transition: 0.1s;
      width: calc(100% - 80px);
      transition-property: left;
    }
  }
}

.left_bar {
  transition: 0.3s;
  @media (max-width: 460px) {
    width: 67px;
    margin-left: -10px;
    &.left_bar_small {
      display: none;
    }
  }
  @media (min-width: 461px) {
    width: 200px;
    &.left_bar_small {
      width: 79px;
    }
  }
}

body {
  background-color: #fafafa;
  margin: 0px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

@keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0px 300px;
  }
}
</style>
