<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftBar: {
        show: true,
      },
    };
  },
  methods: {
    // hide left nav bar
    changeLeftBarState(state) {
      this.leftBar.show = state;
    },
  },
  created() {
    // if (!this.$store.getters.AUTH_TOKEN) {
    //   this.$router.push("/login")
    // }
  },
};
</script>

<style>
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: auto;
  src:
    local("Roboto"),
    url(./fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

body {
  background-color: #fafafa;
  margin: 0px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
</style>
